/**
 * NOTE: this component pre-renders at build with the default (Vio) brand, once JS loads the brand is applied
 * See the `client` package for an example of using `getStaticPaths` to generate HTML files per brand.
 */

import {AppProps} from 'next/app'
import Head from 'next/head'
import {useRouter} from 'next/router'
import Settings from 'Settings'

import {getBrandOverrideFromCookie} from '@daedalus/core/src/_web/brand/business/persistData'
import {getBrandCodeFromUrlOrCookie} from '@daedalus/core/src/_web/brand/utils'
import {defaultBrand, getBrandByCode} from '@daedalus/core/src/brand/config'

import {PageProps as IndexPageProps} from './'

/**
 * Could be pageProps from any page
 */
type AllPageProps = IndexPageProps | undefined

const App = ({Component, pageProps}: AppProps<AllPageProps>) => {
  const router = useRouter()
  const brandOverrideCode =
    router.query.brandOverride || getBrandOverrideFromCookie()
  const brandOverride =
    typeof brandOverrideCode === 'string' && getBrandByCode(brandOverrideCode)

  let brand = brandOverride || pageProps?.brand
  if (!brand) {
    if (typeof window !== 'undefined') {
      brand = getBrandByCode(
        getBrandCodeFromUrlOrCookie(Settings.get('REACT_APP_ENV') || '')
      )
    } else {
      brand = defaultBrand
    }
  }

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href={`/${brand.code}_favicon.png`} sizes="any" />
        <title>{`Checkout - ${brand.name}`}</title>
        <meta name="description" content={brand.metaDescription} />
        <link rel="stylesheet" href={`/assets/css/${brand.theme}.css`} />

        <script
          type="text/javascript"
          id="osano-constent-mode"
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer ||[];
              function gtag(){dataLayer.push(arguments);}
              gtag('consent','default',{
                'ad_storage':'denied',
                'analytics_storage':'denied',
                'ad_user_data':'denied',
                'ad_personalization':'denied',
                'personalization_storage':'denied',
                'functionality_storage':'granted',
                'security_storage':'granted',
                'wait_for_update': 500
              });
              gtag("set", "ads_data_redaction", true);
            `
          }}
        />
        <style>{`.osano-cm-dialog,.osano-cm-widget{display: none;}`}</style>
        <script
          type="text/javascript"
          id="osano-cross-device-consent"
          dangerouslySetInnerHTML={{
            __html: `
              ;(function(w,o,d){w[o]=w[o]||function(){w[o][d].push(arguments)};w[o][d]=w[o][d]||[]})(window,'Osano','data');
              if(window.osanoMobileAppIdentifier) {
                window.Osano('userData', window.osanoMobileAppIdentifier);
              }`
          }}
        />
        <script
          async
          type="text/javascript"
          src={`https://cmp.osano.com/AzZuEAUQXGtUb3YcB/${brand.cookieConsentId}/osano.js`}
        />
      </Head>
      <Component {...pageProps} />
    </>
  )
}

export default App
